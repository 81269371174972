import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CPageNav,
  CFaqList,
  LContact,
} from '../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'FAQ',
            sub: 'よくあるご質問',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'よくあるご質問',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <CPageNav
            data={[
              {
                label: 'ご宿泊',
                link: {
                  href: '',
                },
              },
              {
                label: 'レストラン＆バー',
                link: {
                  href: '/faq/restaurants/',
                },
              },
              {
                label: 'ウエディング',
                link: {
                  href: '/faq/wedding/',
                },
              },
              {
                label: 'ご宴会・会議',
                link: {
                  href: '/faq/banquet/',
                },
              },
              {
                label: '館内施設',
                link: {
                  href: '/faq/facility/',
                },
              },
              {
                label: 'アクセス',
                link: {
                  href: '/faq/access/',
                },
              },
            ]}
          />
          <CFaqList
            hash=""
            data={[
              {
                q: 'チェックイン・チェックアウトは何時ですか?',
                a: (
                  <>
                    チェックインは15:00から、チェックアウトは11:00までです。
                    <br />
                    ただし、プランにより異なりますので、ご予約のプラン内容をご確認ください。
                  </>
                ),
              },
              {
                q: '部屋からはどんな眺望が楽しめますか?',
                a: (
                  <>
                    大きく分けて海側と街側の2方向の眺望がお楽しみいただけます。
                    <br />
                    横浜は港街ですので、ベイブリッジなどがご覧いただける海側（北東・ベイサイド）が人気です。
                    <br />
                    夜景をご堪能される場合は、明かりが多い街側をおすすめしております。
                    <br />
                    詳しくは
                    <Link to="/stay/#view">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '部屋の階数指定はできますか?',
                a: (
                  <>
                    ご予約時に階数の指定は承っておりません。
                    <br />
                    お部屋の階数は、当日チェックインの際にご案内させていただいております。
                  </>
                ),
              },
              {
                q: 'チェックイン前やチェックアウト後でも荷物を預かってくれますか?',
                a: <>いずれもホテル棟1F ベルキャプテンデスクにてお預かりいたします。</>,
              },
              {
                anchor: 'q',
                q: '事前に荷物を送りたいのですが。',
                a: (
                  <>
                    ご宿泊日とご宿泊者名を明記の上、下記住所にお送りください。
                    <br />
                    なお、お荷物はお部屋のご予約をされてからお送りください。
                    <br />
                    宛先：
                    <br />
                    〒220-8173 横浜市西区みなとみらい2-2-1-3
                    <br />
                    横浜ロイヤルパークホテル ベルキャプテンデスク気付
                    <br />
                    <a href="tel:0452211111">TEL 045-221-1111</a>
                    <br />
                    記載事項：
                    <br />
                    ご宿泊日、ご予約のお名前（カタカナ）
                    <ul className="c_noteList">
                      <li>
                        食品のご郵送・お持込みはご遠慮ください。
                      </li>
                      <li>
                        サプライズプレゼントをお送りの際は、宿泊日前日までにホテル代表（<a href="tel:0452211111">TEL 045-221-1111</a>）へご連絡ください。
                        <br />
                        ご連絡がない場合、配送伝票に記載のご連絡先に確認のご連絡をする場合がございます。
                        <br />
                        プレゼントをお持込みの際は、ホテル棟1F ベルキャプテンデスクにてお預かりいたします。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                q: '子供料金はいくらですか?',
                a: (
                  <>
                    添い寝のお子様は小学生までとし、中学生以上のお客様は大人料金を頂戴いたします。
                    <br />
                    ただし、小学生以下のお子様でも、ベッド1台をご利用になる場合は、大人料金にて承ります。
                    <br />
                    添い寝のお子様は大人1名につき1名までとし、それを超える場合は大人料金を頂戴いたします。
                    ベビーベッドやベッドガードは無料でご用意いたしますので、ご予約時にお申付けください。
                    <br />
                    なお、安全設計上、ベビーベッドのご利用は1歳未満で10kg以下のお子様に限らせていただいております。
                    <br />
                    また、スカイリゾートフロア「ザ･クラブ」にて添い寝をご希望される場合、小学生のお子様には施設利用料1泊1名あたり2,000円を頂戴いたします。
                    <br />
                    <ul className="c_noteList">
                      <li>料金には消費税、サービス料が含まれます。</li>
                    </ul>
                  </>
                ),
              },
              {
                q: '記念日などで利用する場合にどのようなサービスがありますか?',
                a: (
                  <>
                    ●ご宿泊をご利用の場合
                    <br />
                    ディナー付きプランやケーキ付きプランをご用意しておりますので、ぜひご利用ください。
                    <br />
                    その他、花束などのオプションやルームサービスにてケーキやシャンパンのご手配も承ります。
                    <br />
                    <Link to="/anniversary/stay_plan/#option">＞宿泊プランオプション</Link>
                    <br />
                    <Link to="/stay/room_service/">＞ルームサービス</Link>
                    <ul className="c_noteList">
                      <li>
                        サプライズプレゼントをお送り・お持込みの際は、<Link to="/faq/#q">「Q.事前に荷物を送りたいのですが。」</Link>をご覧ください。
                      </li>
                      <li>
                        お送りいただいたお荷物でのお部屋の装飾サービスは行っておりません。
                      </li>
                    </ul>
                    ●レストランをご利用の場合
                    <br />
                    記念撮影のサービスをしております。
                    <br />
                    また、ケーキやお花などがセットになったプランもございますので、詳しくはお問合せください。
                    <br />
                    <ul className="c_noteList">
                      <li>
                        バースデーケーキなど、各種手配も承りますのでご予約の際にお申付けください。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                q: '宿泊料金はいつ払うのですか?',
                a: (
                  <>
                    事前決済またはチェックインの際にお支払いをお願いいたします。
                  </>
                ),
              },
              {
                q: 'キャンセル待ちをしたいのですが?',
                a: (
                  <>
                    キャンセル待ちの受付は承っておりません。
                    <br />
                    ご連絡いただければ、その都度空室状況をご案内させていただいております。
                  </>
                ),
              },
              {
                q: 'キャンセル料はかかりますか?',
                a: (
                  <>
                    ご宿泊をキャンセルされる場合は以下のようにキャンセル料を申受けます。
                    <br />
                    7～2日前のご連絡・・・40％
                    <br />
                    前日のご連絡・・・・・80％
                    <br />
                    当日のご連絡・・・・・100％
                    <br />
                    ご連絡なくご利用がない場合・・・100％
                    <br />
                    <ul className="c_noteList">
                      <li>
                        団体予約、旅行代理店、その他エージェントを経由した場合は上記の限りではございません。宿泊プランによって異なる場合もございますので、ご予約時にご確認ください。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                q: '車で行きたいのですが、駐車場はありますか？また、駐車料金はいくらですか?',
                a: (
                  <>
                    ホテル専用スペースではございませんが、駐車場がございます。
                    <br />
                    詳しくは
                    <Link to="/about/access/car/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: 'サービス料はいくらですか?',
                a: <>本体価格の15%を頂戴いたします。</>,
              },
              {
                q: '喫煙できる客室はありますか?',
                a: (
                  <>
                    全客室禁煙とさせていただいております。
                    <br />
                    タバコを吸われるお客様は、喫煙所（ホテル棟1F）をご利用ください。
                  </>
                ),
              },
              {
                q: '未成年者だけで宿泊できますか?',
                a: (
                  <>
                    未成年の方だけでご宿泊する場合は、保護者の方の同意が必要です。
                    <br />
                    下記より同意書をダウンロードいただき、ご記入・捺印の上、チェックインの際にご提出ください。
                    <br />＞{' '}
                    <a
                      href="/assets/files/pdf/agreement_underage.pdf"
                      target="_blank"
                    >
                      保護者同意書
                    </a>
                  </>
                ),
              },
              {
                q: 'wifiは利用できますか?',
                a: (
                  <>
                    ホテルご利用のお客様は無料でご利用いただけます。
                    <br />
                    詳しくは
                    <Link to="/facilities/business/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LContact
        exClass="u_telUnit_end"
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
